.tr8s-link {
  color: #7a88f1;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  @include fontSize(14px);
}

.tr8s-link:hover {
  margin-bottom: 38px;
  text-decoration: none;
  border-bottom: 1px solid #394eee;
}
