.tr8s-button {
  min-width: 148px;
  height: 45px;
  box-shadow: 0 9px 29px rgba(94, 165, 160, 0.36);
  border-radius: 25px;
  background-color: #27A4A1;
  border: none;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.28px;
  padding: 12px 48px;
  display: inline-block;
  @include fontSize(14px);

  @media screen and (max-width: 576px) {
    padding: 12px 15px;
    @include fontSize(12px);
  }
}

.loginBtn {
  height: 38px;
  margin-top: 8px;
  min-width: 105px;
  @include fontSize(10px);
}

.tr8s-button.mb {
  margin-bottom: 50px;
}

.tr8s-button:focus {
  outline: none;
}

.tr8s-button.green {
  background-color: #27A4A1;
}

.tr8s-button.green:hover {
  background-color: #86c0bc;
  text-decoration: none;
  color: #ffffff;
}

.tr8s-button.red {
  background-color: #ffeded;
  color: #ff4c4c;
  box-shadow: none;
}

.tr8s-button.red:hover {
  background-color: #ff4c4c;
  color: #ffffff;
}

.tr8s-button.orange {
  background-color: #ff954c;
  color: #fff;
  border: none;
  box-shadow: none;
}

.tr8s-button.orange:hover {
  background-color: #feb785;
}

.tr8s-button.gray {
  background-color: #f3f3f3;
  color: #b5b5b5;
  border: none;
  box-shadow: none;
}

.tr8s-button.gray:hover {
  background-color: #e4e4e4;
  color: #333333;
}

.cancel-button {
  color: #27A4A1;
  border: 1px solid #27A4A1;
  background: #FFF;
}
