@media (min-width: 1400px) {
  .container {
    max-width: 90%;
    // overflow-x: hidden;
  }
}
.aside-vacancy-test-stat {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.aside-test-stat-text,
.aside-test-stat-text p,
.aside-test-stat-text span {
  //opacity: 0.8;
  color: rgba(51, 51, 51, 0.8);
  @include fontSize(11px);
  font-weight: 700;
  //line-height: 65px;
  text-transform: uppercase;
  letter-spacing: 0.88px;
}

.aside-test-stat-text p,
.aside-test-stat-text span {
  margin-bottom: 21px;
}

.aside-test-stat-text span {
  @include fontSize(13px);
  letter-spacing: 1.04px;
}

.aside-test-stat-text.green p,
.aside-test-stat-text.green span {
  color: rgba(94, 165, 160, 0.8);
}

.aside-test-stat-text.gray p,
.aside-test-stat-text.gray span {
  color: rgba(181, 181, 181, 0.8);
}

.aside-test-stat-text.red p,
.aside-test-stat-text.red span {
  color: rgba(243, 74, 74, 0.8);
}

.mlr0s {
  @media screen and (max-width: 298px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.profile-user-name {
  color: #333333;
  margin-bottom: 0;
  line-height: 30px;
  position: relative;
  @media screen and (max-width: 800px) {
    display: flex;
    align-items: center;
    width: 125px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

//.profile-user-name:after {
//  content: '';
//  position: absolute;
//  top: 6px;
//  left: 0;
//  height: 17px;
//  width: 17px;
//}

.language-control {
  color: #333333;
  margin-bottom: 0;
  line-height: 30px;
  position: relative;
}

//.language-control:after {
//  content: '\f0ac';
//  position: absolute;
//  top: 6px;
//  left: 0;
//}

.profile-edit-button {
  position: relative;
  width: 85px;
  height: 46px;
  border-radius: 24px;
  background-color: #f3f3f3;
  margin-bottom: 50px;
  outline: none;
  border: none;

  color: #b5b5b5;
  @include fontSize(14px);
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.26px;
  padding-left: 28px;

  //margin-bottom: 0;
}

.profile-edit-button:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  background: url(/assets/img/aside-settings-icon.png);
  height: 17px;
  width: 17px;
}

.aside-test-stat-text.title {
  margin-bottom: 28px;
}

.profile-setting-input {
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.15);
  border-radius: 0;
  padding-left: 0;

  color: rgba(51, 51, 51, 0.43);
  @include fontSize(15px);
  line-height: 30px;
  padding-top: 0;
  margin-bottom: -4px;
}

.profile-setting-input:focus {
  box-shadow: none;
  border-color: #000;
  color: rgba(51, 51, 51, 1);
}

.profile-info-title {
  color: #333333;
  @include fontSize(23px);
  margin-top: 43px;
  text-align: center;
  margin-bottom: 35px;
}

.profile-info-subtitle {
  color: #323232;
  @include fontSize(18px);
  margin-bottom: 7px;
}

.test-result-block {
  box-shadow: 0 6px 21px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 17px 40px 17px 20px;
  margin-bottom: 15px;
}

.test-result-title {
  color: #333333;
  @include fontSize(25px);
  margin: 25px 0;
  letter-spacing: -1px;

  @media screen and (max-width: 767px) {
    margin: 15px 0;
  }
}

.result-subtitle {
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.test-result-title-assessments {
  color: #333333;
  @include fontSize(25px);
  margin-top: 6px;
  letter-spacing: -1px;
  margin-bottom: 24px;
}

.progressInfoAssesments {
  text-align: left;
  @include fontSize(10px);
}

.countProgressAssesments {
  @include fontSize(16px);
}

.assessmentCard {
  width: 350px;
  margin: 8px;
}

.begin {
  outline: none !important;
  border-radius: 21px;
  background-color: #27a4a1;
  color: #ffffff;
  @include fontSize(13px);
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.26px;
  border: none;
  margin-top: 0px;
  margin-left: 23px;
  padding: 6px 39px;
}

.test-result-begin {
  @include fontSize(9px);
  height: 30px;
  padding: 0;
  width: 80px;
  margin-left: 56px;
}

.test-result-header-group {
  margin-left: 8px;
}

.test-result-header {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
  }
}

.test-result-header-title {
  @include fontSize(14px);
  letter-spacing: -1px;
  font-weight: 600;
  margin: 0;
}

.test-result-header-desc {
  @include fontSize(10px);
  color: #c7c7c7;
  margin: 0;
}
.test-result-subtitle {
  color: #323232;
  @include fontSize(18px);
  margin-bottom: 22px;
  letter-spacing: -1px;
  @include fontSize(15px);
}

.test-result-block-title {
  color: #6e6e6e !important;
  @include fontSize(11px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.88px;
}

.test-result-block-link {
  color: #7a88f1;
  @include fontSize(14px);
  font-weight: 400;
  margin-bottom: 32px;
  display: block;
}

.test-result-block-progress {
  height: 5px;
  border-radius: 2px;
  background-color: #d1d1d1;
  margin-top: 9px;
}

.test-result-block-progress-bar {
  height: 5px;
  background-color: #27a4a1;
}

.test-result-block-test-title {
  opacity: 0.39;
  color: #333333;
  @include fontSize(14px);
  margin-bottom: 0;
}

.test-result-block-number {
  display: flex;
}

.test-result-block-test-number {
  width: 25px;
  height: 25px;
  background-color: #d0d0d0;
  display: block;
  border-radius: 15px;
  margin-right: 17px;
}

.test-result-block-test-number span {
  display: block;
  height: 25px;
  width: 25px;
  text-align: center;
  color: #ffffff;
  @include fontSize(12px);
  font-weight: 700;
  line-height: 25px;
  margin-right: 17px;
  position: relative;
}

.test-result-block-test-number.active span:after {
  content: '';
  position: absolute;
  left: 10px;
  top: 5px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.test-result-block-test-number.active {
  background: #27a4a1;
}

.test-result-block-test-number.active span {
  color: #27a4a1;
}

.test-result-block-title {
  margin: 0;
  color: #c2c2c2;
  @include fontSize(7px);
  margin-bottom: 4px;
}

.progressSection {
  margin-left: 8px;
}

.progressBar {
  color: #c2c2c2;
  display: flex;
  height: 27px;
  align-items: center;
  margin-bottom: 10px;
}
.pregressSection {
  margin-left: 15px;
}

.progressText {
  margin: 0;
  font-weight: 700;
  color: #6e6e6e;
  @include fontSize(9px);
  text-transform: uppercase;
}

.progressStatus {
  margin: 0;
  color: #27a4a1;
  font-weight: 700;
}

.viewButton {
  display: flex;
  justify-content: center;
  min-height: 38px;
}

.completedCard {
  background: #f6f6f6;
  padding: 30px 17px 30px 16px;
  height: 100%;
}

.viewDetailButton {
  outline: none !important;
  user-select: none;
  border: 1px solid #27a4a1;
  background: transparent;
  padding: 10px 30px;
  color: #323232;
  font-weight: 600;
  border-radius: 25px;
  @include fontSize(13px);
  margin: 0 auto;
}

.deletedMark {
  @include fontSize(10px);
  color: #f47f7f;
  margin-bottom: 5px;
  user-select: none;
}

.test-result-block-title.company {
  margin-bottom: 20px;
}

.position {
  padding: 0;
  font-weight: 600;
  @include fontSize(12px);
}

.test-result-block-title.test {
  margin-bottom: 12px;
  margin-top: 10px;
}

.test-result-block-button-wrapper {
  display: flex;
  justify-content: space-between;
}

.colMargin {
  margin-top: 15px;
  margin-left: 12px;
  width: calc(20% - 15px);
}

.jobCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 12px #c8c8c8;
  padding: 18px 12px;
  min-width: 200px;
}

.companyName {
  color: #a4a4a4;
  @include fontSize(10px);
}

.test-result-block-button {
  outline: none !important;
  border-radius: 18px;
  background-color: #27a4a1;
  color: #ffffff;
  @include fontSize(13px);
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.26px;
  outline: none;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 5px;
  @include fontSize(10px);
}

.test-result-block-button-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  border-radius: 50%;
  background-color: #eaeaea;
  width: 32px;
  height: 32px;
  color: #909191;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.26px;
  outline: none;
  border: none;
  margin-top: 4px;
}

.main-content-wrapper {
  padding-right: 14px;
}

.test-result-block.finished {
  background-color: #f6f6f6;
  box-shadow: none;
}

.test-result-block-left {
  border-right: 1px solid #dfdfdf;
}

.test-result-block-right-content {
  margin-bottom: 9px;
}

.test-result-block-img {
  margin-bottom: 8px;
  max-width: 120px;
  max-height: 20px;
  object-fit: contain;
  align-self: flex-start;
}

.arrowRight {
  width: 16px;
  height: 16px;
  transform: scale(-1);
  margin-right: 10px;

  &.rtl {
    transform: scale(-1) rotate(180deg);
  }
}

.goVacancy,
.goVacancy:hover {
  text-decoration: none;
  color: #6e6e6e;
  margin-top: 20px;
  @include fontSize(12px);
}

.progressStatus {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.countProgress {
  @include fontSize(20px);
  font-weight: 700;
}

.titleAssessments {
  @include fontSize(11px);
  font-weight: 700;
  color: #6e6e6e;
  margin: 0;
  text-transform: uppercase;
}

.titleAssessments p {
  @include fontSize(12px);
}

.vacancyTitle {
  @include fontSize(15px);
  color: #3f3f3f;
  font-weight: 600;
  margin-top: 4px;
  @include fontSize(10px);
  margin-left: 5px;
  font-weight: 700;
  color: #c8c8c8;
  margin-bottom: 2px;
}

.vacancyStatus {
  color: #ff954c;
}

.startTest {
  color: #333333;
  @include fontSize(20px);
  margin-top: 20px;
  margin-bottom: 10px;
}

.titleForWebDev {
  margin-bottom: 2px;
  color: #5a5a5a;
  @include fontSize(12px);
  margin-left: 15px;
  letter-spacing: -0.5px;
  font-weight: 700;

  &.rtl {
    margin-left: 0;
    margin-right: 15px;
  }
}

.buttonNext {
  display: flex;
}

.vacancyDescription {
  color: #bebebe;
  @include fontSize(11px);
  margin-left: 5px;
  margin-top: 1px;
}

.assessmentBtnNext {
  min-width: 105px;
  padding: 0;
  @include fontSize(10px);
  margin-top: 30px;
  height: 35px;
  cursor: pointer;
}

.assessmentBtnNext:disabled:hover {
  background: #f3f3f3;
  color: #b5b5b5;
}

.bottmWrapper {
  margin-top: -9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottmWrapperStatus {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.bottomWrapperStatusInfo {
  margin-right: 34px;
}

.icon {
  width: 50px;
  height: 50px;
}

.infoContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sliderImage {
  width: 100%;
}

.sliderWrapper {
  justify-content: center;
  max-width: 400px;
  margin: auto;

  .carousel.slide {
    max-height: 460px;
  }
}

.infoWrapper {
  display: flex;
  margin-right: 50px;
  margin-bottom: 10px;

  &.rtl {
    margin-left: 50px;
    margin-right: 0;
  }
}

.Infotype {
  text-transform: uppercase;
  color: #6e6e6e;
}
.textInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;

  &.rtl {
    margin-right: 15px;
    margin-left: 0;
  }
}

.textInfo p {
  margin: 0;
}

.textInfo p:first-child {
  color: #6e6e6e;
  @include fontSize(9px);
  font-weight: 900;
}

.textInfo p:last-child {
  font-weight: 900;
}

.testTitle {
  font-weight: 700;
  @include fontSize(12px);
}

.test-example {
  color: #333333;
  @include fontSize(22px);
  margin-top: 20px;
  margin-bottom: 15px;
}

.list {
  @include fontSize(12px);
  line-height: 23px;
  padding-left: 20px;
  margin-bottom: -13px;
}

.startNow {
  margin-top: 18px;
  min-width: 186px;
  padding: 0;
  height: 36px;
  @include fontSize(10px);
}

.completeTitle {
  color: #333333;
  @include fontSize(20px);
  margin-top: 47px;
  margin-bottom: 24px;
}

.goToDashboard {
  min-width: 128px;
  padding: 0 38px;
  height: 36px;
  @include fontSize(10px);
}

.saveProfile {
  min-width: 105px;
  padding: 0;
  height: 36px;
  margin-top: 13px;
  margin-right: 22px;
  @include fontSize(10px);
}

.uploadCV {
  margin-bottom: 7px;
  margin-top: -2px;
  margin-left: -16px;
  @include fontSize(18px);
}

.uploadCVText {
  @include fontSize(12px);
  margin-left: -15px;
  margin-top: 16px;
  line-height: 21px;
}

.companyLogo {
  position: relative;
  margin: 0 15px;
  height: 20px;
}

.endTest {
  background: #fdedee;
  margin-right: 20px;
  color: #f55f61;
  @include fontSize(10px);
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;
}

.saveAndClose {
  background: #dfedec;
  color: #27a4a1;
  @include fontSize(10px);
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    padding: 2px 10px;
  }
}

.iconCard {
  width: 37px;
  margin: -6px 0 0 2px;
  height: 44px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

.label {
  @include fontSize(10px);
  transition: 0.4s;
  color: rgba(51, 51, 51, 0.43);
}

.endAssessmentButton {
  position: absolute;
  right: 200px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;

  &.rtl {
    right: unset;
    left: 220px;
  }
}

.carousel-control-next {
  right: -30px !important;
}

.carousel-control-prev {
  left: -30px !important;
}

.gameInfoButtons {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 5px solid #cccccc;
  opacity: 1;
  background: transparent;
}

.carousel-indicators {
  bottom: -45px;
  padding: 0;
}

.carousel-indicators .active {
  background: #27a4a1;
}

.gameInfoBottomWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 40px;
}

.selectedItemTestGames {
  position: absolute;
  z-index: 2;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  &.rotateIt,
  &.shapes {
    background: rgba(125, 187, 178, 0.7);
    width: 95px;
    height: 95px;
    top: -5px;
  }

  &.correct {
    background: rgba(125, 187, 178, 0.7) !important;
  }

  &.incorrect {
    background: rgba(244, 83, 93, 0.7) !important;
  }
}

.selectedItemTestGames.rotateIt {
  background: rgba(125, 187, 178, 0.7);
  width: 95px;
  height: 95px;
  top: -5px;
}

ul {
  color: #323232;
  @include fontSize(14px);
  font-weight: 400;
}

.gameInfoPlay {
  text-align: center;
  @include fontSize(12px);
}

.activeInputProfile {
  color: #000;
}

.scrollingTable {
  overflow-y: auto;
}

@media screen and (max-width: 767px) {
  .test-result-block-title.test {
    border-top: 1px solid #dfdfdf;
    padding-top: 23px;
  }

  .carousel-control-next {
    right: 80px;
  }

  .carousel-control-prev {
    left: 80px;
  }

  .test-result-title {
    @include fontSize(17px);
    text-align: center;
  }

  .test-result-subtitle {
    text-align: center;
    @include fontSize(17px);
  }

  .main-content-wrapper {
    padding-right: 0;
  }

  .test-result-block {
    padding: 17px 20px 17px 20px;
  }

  .test-result-block-left {
    border-right: none;
  }

  .endAssessmentButton {
    right: 180px;

    &.rtl {
      right: unset;
      left: 200px;
    }
  }
}
@media screen and (max-width: 425px) {
  .colMargin {
    width: 100%;
    margin: 8px 24px;
  }
  .row {
    justify-content: center;
  }
}
