.personalityTest-circular-progress-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 20px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
}
.personalityTest-card {
  width: 80%;
  padding: 20px 2% 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c0c1c3;
  border-radius: 10px;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.personalityTest-card .CircularProgressbar {
  max-width: 95px;
  min-width: 90px;
  margin-bottom: 15px;
}
.personalityTest-card span {
  margin: 0 0 15px 10px;
  font-weight: 600;
  text-align: center;
  @include fontSize(24px);
}
.significient-block-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid #000;
}
.significient-identity,
.performing-best {
  width: 100%;
}
.significient-identity h3,
.performing-best h3 {
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.identity-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.identity-block:last-child,
.performing-best-list:last-child {
  border-bottom: 0;
}
.identity-block .identity-svg {
  width: 15%;
  margin-right: 20px;
}
.identity-block-content {
  width: 70%;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.identity-block-content h4 {
  margin-bottom: 5px;
}
.identity-block-content span {
  display: block;
  @include fontSize(16px);

  @media screen and (max-width: 576px) {
    @include fontSize(14px);
  }
}
.performing-best-list {
  border-bottom: 1px solid #ccc;
  li {
    overflow: visible !important;
  }
}
.overflow-li-visible {
  li {
    overflow: visible !important;
  }
}
.Career {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 100px;
}
.carrer-content {
  display: flex;
  justify-content: space-around;
}
// Media Query for Tablet
@media only screen and (min-width: 480px) {
  .personalityTest-circular-progress-block {
    justify-content: space-between;
  }
  .personalityTest-card {
    width: 31.33%;
  }
  .significient-identity,
  .performing-best {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .significient-identity,
  .performing-best {
    width: 48%;
  }
}

.personality-test-form-position {
  margin-top: 30px;
}
