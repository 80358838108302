
.content-title {
  color: #333333;
  margin-top: 70px;
  margin-bottom: 43px;
  @include fontSize(32px);
}

.content-text {
  color: #333333;
  line-height: 30px;
  @include fontSize(18px);
}

.content-text-assessment {
  margin-bottom: 5px;
  margin-top: 0px;
  line-height: 25px;
  @include fontSize(14px);
}

.content-text-preparation {
  margin-bottom: 16px;
  @include fontSize(13px);
}

.content-title.assessment-title {
  margin-top: 20px;
  margin-bottom: 20px;
  @include fontSize(22px);
}

@media screen and (max-width: 1200px) {
  .content-title {
    @include fontSize(25px);
  }
}

@media screen and (max-width: 991px) {
  .content {
    border-right: none;
  }

  .content-title {
    margin-top: 27px;
    margin-bottom: 29px;
  }
}
