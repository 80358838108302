.testProgreeBarWrapper {
    display: flex;
    @include fontSize(10px);
    color: #ffffff;
    font-weight: 700;
}

.testProgreeBarInfo {
    background: #27A4A1;
    margin-right: 3px;
    width: 65px;
    padding: 2px 0;
    letter-spacing: 2px;
    text-align: center;
}

.progressStatusBarWrapper {
    background: #eaeaea;
    width: 100%;
}

.progressStatusBaColor {
    background: #27A4A1;
    height: 100%;
    transition: .4s;
}