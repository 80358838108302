.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: 100;
}

.modalContent {
    position: relative;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    padding: 40px 50px 30px;
    background: #ffffff;
    max-width: 350px;
    border-radius: 12px;
    text-align: center;

    @include fontSize(12px);

    @media screen and (max-width: 480px) {
        padding: 40px 20px 30px;
        max-width: calc(100% - 30px);
    }
}

.modalButtonsGroup {
    display: flex;
    justify-content: space-between;
}

.modalButton {
    outline:none !important;
    color: #ffffff;
    font-weight: 700;
    border: none;
    border-radius: 20px;
    cursor: pointer !important;
    height: 36px;
    width: 115px;
    @include fontSize(10px);
}

.onDeleteBtn {
    background: #f57878;
    box-shadow: 0 0 18px #f57878;
}

.onCancelBtn {
    background: #27A4A1;
    box-shadow: 0 0 18px #27A4A1;
}

.timesClosed {
    position: absolute;
    right: 15px;
    top: 0px;
    color: #c8c8c8;
    user-select: none;
    cursor: pointer;
    @include fontSize(30px);
}
