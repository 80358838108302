.radiobox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include fontSize(22px);
  }
  
  .radiobox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmarkRadio {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    background-color: #cdcdcd;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
  }
  
  .checkmarkRadio.radio {
    border-radius: 27px;
  }
  
  .radiobox-container:hover input ~ .checkmarkRadio {
    background-color: #c8c8c8;
  }
  
  .radiobox-container .l:checked ~ .checkmarkRadio {
    background-color: #27A4A1;
    border: 1px solid #27A4A1;
  }

  .radiobox-container .r:checked ~ .checkmarkRadio {
    background-color: #f4494a;
    border: 1px solid #f4494a;
  }
  
  .checkmarkRadio:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .cheradioboxckbox-container input:checked ~ .checkmarkRadio:after {
    display: block;
  }
  
  .radiobox-container .checkmarkRadio:after {
    left: 9px;
    top: 5px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }

  .sjt-thumb-container.radiobox-container {
    width: 40px;
    height: 40px;
  }
  
  .thumbUp {
      z-index: 20;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
  }

  .thumbDown {
    z-index: 20;
    position: absolute;
    width: 25px;
    height: 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(-1, -1);
  }
