.vacancy-title {
  color: #303030;
  @include fontSize(25px);
  margin-top: 49px;
  margin-bottom: 35px;
}

.vacancy-subtitle {
  color: #2e2e2e;
  @include fontSize(17px);
  line-height: 24px;
  margin-bottom: 20px;
}

.vacancy-text {
  color: #2e2e2e;
  @include fontSize(14px);
  line-height: 26px;
  margin-bottom: 37px;
}

.vacancy-text.last {
  margin-bottom: 47px;
}

.vacancy-btn {
  margin-bottom: 131px;
}

.vacancy-features {
  border-radius: 4px;
  background-color: rgba(255, 149, 76, 0.06);
  padding: 20px;
  margin-bottom: 40px;
}

.vacancy-feature-item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .vacancy-features {
    padding-bottom: 0;
  }

  .vacancy-feature-item {
    margin-bottom: 20px;
  }
}

.vacancy-feature-item > .icon {
  width: 43px;
  height: 43px;
  background-color: rgba(231, 149, 76, 0.16);
  display: inline-block;
  margin-right: 9px;
  border-radius: 50px;
  padding: 12px;
}

.vacancy-feature-item > .icon img {
  vertical-align: baseline;
  max-width: 20px;
  max-height: 20px;
}

.vacancy-feature-item > .text > h5 {
  opacity: 0.57;
  color: #302f2f;
  @include fontSize(9px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.54px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.vacancy-feature-item > .text > p {
  color: #302f2f;
  @include fontSize(14px);
  margin: 0;
}
