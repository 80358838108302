.test-one-type-one {
  margin-bottom: 41px;
}

.test-one-type-one tbody tr th {
  color: #2e2e2e;
  @include fontSize(15px);
  font-weight: 400;
  line-height: 30px;
  padding-right: 75px;
}

.test-one-type-one tbody tr td label {
  height: 25px;
  padding: 0;
  margin: 0;
  width: 25px;
}

.test-one-type-one tbody tr td {
  padding-right: 41px;
  padding-bottom: 19px;
}

.test-one-type-one thead tr th p {
  color: #2e2e2e;
  @include fontSize(13px);
  font-weight: 700;
  letter-spacing: 0.78px;
  padding-bottom: 18px;
  width: 25px;
  text-align: center;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .test-one-type-one tbody tr th {
    padding-right: 10px;
  }
  .test-one-type-one tbody tr td {
    padding-right: 14px;
  }
}
