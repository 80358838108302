.slider-game-example {
  width: 300px;
}

.game-card {
  &-btn {
    margin: 0 0 0 3px;
    padding: 1px 28px;
    @include fontSize(10px);
  }

  &-small-text {
    @include fontSize(10px);
  }

  &-status-not-started {
    color: #dc6a54;
    @include fontSize(10px);
  }

  &-status-pending {
    color: #27A4A1;
    @include fontSize(10px);
  }
}

.start-practice-btn {
  margin: 50px 0;
  padding: 1px 28px;
  @include fontSize(10px);
}

.direction-icons {
  color: #565656;

  &-big {
    @include fontSize(20px);
  }
}

.game-info-text.content-text-assessment {
  margin-bottom: 15px;
  @include fontSize(14px);
}

.game-slider .carousel.slide {
  max-width: 300px;
}

.matched-game-slider.sliderImage {
  width: 90%;
  margin: 0 auto;
}
