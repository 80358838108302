/* Main */
body {
  font-family: 'Open Sans', sans-serif !important;
  &:dir(rtl), &:lang(ar) {
    font-family: 'Noto Naskh Arabic' !important;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #333333;
}

h1 {
  @include fontSize(32px);
}

h2 {
  @include fontSize(23px);
}

h3 {
  @include fontSize(18px);
}

h4 {
  @include fontSize(17px);
}

h5 {
  @include fontSize(16px);
}

h6 {
  @include fontSize(14px);
}

p {
  color: #323232;
  font-weight: 400;
}

.social-button {
  width: 78px;
  height: 40px;
  border-radius: 20px;
  background-color: #d64937;
  display: block;
  color: #fff;
  padding: 8px;
}

.social-button:hover {
  color: #ffffff;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
ul{
  list-style: none;
}
ul li{
  position: relative;
  margin-bottom: 1px;
}
ul li:before{
  position: absolute;
  content: '';
  top: 9px;
  left: -10px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #25a4a1;
}