.auth-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
}

.auth-form-container {
  width: 100%;
  margin: 20px auto;
}

.login-logo {
  height: 150px;
  object-fit: contain;
  max-width: 50%;
  margin: 50px auto;
  @media screen and (max-width: 576px) {
    height: unset;
    max-width: 80%;
  }
}

.errorBanner {
  background: #fdeced;
  color: #f67b7b;
  margin: 15px 0 25px 0;
  padding: 3px;
  font-weight: 500;
  border-radius: 4px;
  @include fontSize(12px);
}

.successBanner {
  background: #6ba7a6;
  color: #ffffff;
  margin: 15px 0 25px 0;
  padding: 3px;
  font-weight: 500;
  border-radius: 4px;
  opacity: 1;
  animation: fade 5s forwards;
  @include fontSize(12px);
}

@media screen and (max-width: 576px) {
  .auth-form-container form {
    margin: 30px;
  }
}

.auth-form-title {
  color: #333333;
  line-height: 30px;
  margin-bottom: 9px;
  @include fontSize(20px);
  @media screen and (max-width: 576px) {
    @include fontSize(14px);
  }
}

.auth-form-text {
  color: #333333;
  line-height: 30px;
  letter-spacing: 0.26px;
  margin: 0 31px 36px 31px;
  @include fontSize(13px);
}

@media screen and (max-width: 1170px) {
  .auth-form-title {
    margin-bottom: 30px;
    bottom: 31px;
  }
}

/* Input */
.auth-form-input {
  border: none;
  color: #333333;
  font-weight: 400;
  line-height: 30px;
  border-bottom: 1px solid #333333;
  border-radius: 0;
  outline: none;
  display: block;
  width: 100%;
  margin-bottom: 12px;
  @include fontSize(12px);
}

.auth-form-input:focus {
  opacity: 1;
}

.forgot-password-btn {
  color: rgba(46, 46, 46, 0.39);
  line-height: 26px;
  top: 3px;
  position: absolute;
  letter-spacing: 0.1px;
  right: 45px;
  @include fontSize(9px);

  &.fas{
    cursor: pointer;
    color: rgba(46, 46, 46, 1);
    right: 10px;
    @include fontSize(24px);
  &.rtl {
    left: 10px;
    right: unset;
  }
  }
  &.rtl {
    left: 45px;
    right: unset;
  }
}

.change-password-btn {
  width: 120px;
}

.send-password-reset-btn {
  min-width: 150px;
}

.login-signup-switch {
  text-align: center;
  margin-top: -22px;
}

.link-msg-btn {
  width: 156px;
  height: 36px;
  padding: 0;
  margin: 5px 0 15px 0;
  @include fontSize(10px);
}

@media screen and (max-width: 576px) {
  .auth-form-container form {
    margin: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
