.cardWrapper {
    display: flex;
    flex-direction: column;
    width: 190px;
    height: 130px !important;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 11px #cccccc;
    padding: 12px;
    margin: 4px;
    position: relative;
    z-index: 1;
    outline: none;

    @media only screen and (max-width: 767px) {
        width: 160px; }

    @media screen and (max-width: 576px) {
        width: 90px;
        padding: 12px 5px; }

    .headSection {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        @media only screen and (max-width: 767px) {
            margin-bottom: 5px; }

        @media screen and (max-width: 576px) {
            flex-wrap: wrap;
            justify-content: center;
            height: inherit;

            *:first-child {
                margin-bottom: 5px; } }


        .cardIcon {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 8px;

            &.rtl {
                margin-right: 0px;
                margin-left: 8px; }

            @media screen and (max-width: 576px) {
                margin-right: 0;
                width: 100%; } }

        .cardTitle {
            display: flex;
            align-items: center;
            color: #656565;
            font-size: 14px;
            font-weight: 600;

            @media screen and (max-width: 576px) {
                font-size: 12px;
                text-align: center; } } }

    .descriptionSection {
        font-size: 12px;
        color: #b2b2b2;
        width: 100%;

        @media only screen and (max-width: 767px) {
            font-size: 10px; }

        @media screen and (max-width: 576px) {
            display: none; } } }

.cardWrapper-dragging {
    margin: 0 8px 8px 0; }

.cards {
    width: 1010px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    padding: 8px; }

.drivers-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: inherit;
    overflow: hidden; }

.cards-wrapper-desktop {
    min-width: 200px;
    overflow: hidden; }

.driver-checkbox-container {
    line-height: 12px;
    font-size: 10px; }

.driver-card-border {
    border: 1px dashed #27a4a1;
    border-radius: 6px; }

.driver-card-placeholder {
    border: 2px dashed #e6e6e6;
    border-radius: 6px; }

.cards__disable_anim {
    & > div {
        transform: translate(0px, 0px)!important; } }

.card-area {
    width: 1205px;
    height: 310px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 8px;
    background-color: #fff;
    border: 2px dashed rgb(230, 230, 230);
    border-radius: 12px; }

.hide-cards {
    .cardWrapper {
        display: none; } }

.sceleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 198px;
    height: 406px;
    margin: 8px 0;
    //z-index: 1

    @media only screen and (max-width: 767px) {
        width: 180px; }

    @media screen and (max-width: 576px) {
        width: 100px; }

    .cardWrapper {
        height: 60px!important;
        outline: none;

        .headSection {}
        @media screen and (max-width: 576px) {

            *:nth-child(2) {
                display: flex;
                justify-content: center;
                height: 75px;
                position: absolute;
                top: 55px;
                left: 0;
                padding: 0 5px 12px;
                background: #fff;
                box-shadow: 0 5px 5px #cccccc;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                min-width: 100%; } }

        .descriptionSection {
            font-size: 12px;
            height: 75px;
            color: #b2b2b2;
            position: absolute;
            top: 55px;
            left: 0;
            padding: 12px 12px 5px 12px;
            background: #fff;
            box-shadow: 0 5px 5px #cccccc;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            @media only screen and (max-width: 767px) {
                font-size: 10px; }

            @media screen and (max-width: 576px) {
                display: none; } } } }

.importantCard {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    max-width: 310px;
    margin: 0 25px;

    @media only screen and (max-width: 1068px) {
        margin: 0 5px; }

    &-wrapper {
        display: flex;
        justify-content: center; }

    .cardHeader {
        background: #27A4A1;
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        border-radius: 12px 12px 0 0;
        padding: 10px;
        font-weight: 600;
        text-transform: capitalize;

        @media screen and (max-width: 576px) {
            display: flex;
            align-items: center;
            height: 62px; } }

    .cardContentWrapper {
        display: flex;
        flex-direction: column;
        padding: 12px;
        border: 1px solid #e5e5e5;
        border-radius: 0 0 12px 12px;
        align-items: center;
        min-width: 100%;
        //min-height: 580px
        position: relative;

        @media only screen and (max-width: 1068px) {
            padding: 12px 0; } }

    &-mobile {
        width: 100%;
        max-width: 100%; } }

.importantCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }

.card-placeholders {
    width: 1010px;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    position: absolute;
    & > div {
        width: 190px;
        height: 130px;
        margin: 0 8px 8px 0;

        @media only screen and (max-width: 767px) {
            width: 160px; }

        @media screen and (max-width: 576px) {
            width: 90px; } } }

.card-placeholders__important {
    height: 100%;
    padding: 8px;
    position: absolute;
    & > div {
        width: 190px;
        height: 130px;
        margin: 0 8px 8px 0;

        @media only screen and (max-width: 767px) {
            width: 160px; }

        @media screen and (max-width: 576px) {
            width: 90px; } } }


//MOBILE CARDS styles start

.importantCardMobile {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    box-shadow: 0px 3px 15px 0px #93939329;

    &-wrapper {
        display: flex;
        justify-content: center; }

    .cardHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background: #27A4A1;
        color: #ffffff;
        font-size: 16px;
        border-radius: 12px 12px 0 0;
        padding: 10px;
        font-weight: 600;
        text-transform: capitalize; }

    .cardContentWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-radius: 0 0 12px 12px;
        min-width: 100%;
        position: relative; } }


.cardWrapperMobile {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 11px #cccccc;
    padding: 15px;
    margin-bottom: 20px;
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;

    .disabledDriver {
        p {
            color: #cccccc; } }

    p {
        font-size: 18px;
        font-weight: 600; }

    span {
        color: #6e6e6e;
        font-size: 14px; } }


.checkbox-container input.disabled-checkmark ~ .checkmark:active {
    background-color: #f4494a; }
