$itemPadding: 30px;

.report {
  &-xlFont {
    font-sixe: 60px;
  }

  &-lgFont {
    @include fontSize(30px);
  }

  &-mdFont {
    @include fontSize(18px);
  }

  &-xmdFont {
    @include fontSize(16px);
  }

  &-smFont {
    @include fontSize(15px);
  }

  &-xsmFont {
    @include fontSize(13px);
  }

  &-cover {
    page-break-after: always;
    padding-top: 70px;
  }

  &-footerWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &-footer-page {
    position: absolute;
    right: 0;
  }

  &-headerWrapper {
    border-bottom: 2px solid #dddddd;
    padding: 20px 0 20px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  &-download-btn {
    margin-bottom: 30px;
    width: 130px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media print {
  header {
    position: fixed !important;
  }
}

/* styling page manager report */
.person-highlight h1,
//TODO fix typo
.strenght-sec h1,
.strength-sec h1,
.person-highlight h2,
.assessment-sec h2,
.assessment-sec h1,
.career-sec h2 {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.highlight-row h3,
.thumb-block h3 {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.highlight-block .circular-bar-advance-card {
  width: 100%;
  padding: 20px;
  display: block;
  margin-bottom: 30px;
}

.circle-progress {
  position: relative;
}

.count {
  color: rgb(37, 164, 161);
  @include fontSize(24px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -8px;
}

.highlight-row {
  margin-bottom: 40px;
}

.progress-title {
  @include fontSize(14px);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.progress-title img {
  margin-right: 10px;
}

.progress-title svg {
  fill: rgb(37, 164, 161);
  width: auto;
  height: 25px;
  margin-right: 10px;
}

.progress-title strong {
  margin-right: 10px;
}

.custom-trait-table th, .custom-trait-table td {
  padding: 5px;
}

.graph-title {
  @include fontSize(9px);
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
}

.traits-table-text {
  writing-mode: vertical-rl;
  @include fontSize(11px);
  transform: rotate(180deg);
}

.traits-table-text span {
  margin: 10px 0 0 0;
}

.graph-title img {
  fill: #27a4a1;
  margin: 10px 0 0 0;
}

.bg-teal-light {
  background-color: rgba(37, 164, 161, 0.1);
}

.count-pdf{
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: #fff;
  border: 2px solid rgb(37, 164, 161);
  border-radius: 62%;
  position: absolute;
  top: -2px;
  right: -2px;
  color: rgb(37, 164, 161);
  @include fontSize(13px);
  font-weight: 700;
  line-height: 23px;
}
.progres-pdf{
  display: flex;
  height: 1.5rem;
  overflow: hidden;
  line-height: 0;
  font-size: 3.75rem;
  background-color: #e9ecef;
  border-radius: .25rem;
}

.chart-inner {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.summary-text h3 {
  text-align: center;
  text-transform: capitalize;
  color: rgb(37, 164, 161);
  font-weight: 700;
  margin-bottom: 40px;
}

.assessment-sec {
  margin-bottom: 80px;
}

.thumb-block .progress-head svg,
.strenght-sec .identity-svg svg {
  fill: rgb(37, 164, 161);
}

.strenght-sec .identity-block .identity-svg {
  width: 60px;

  @media screen and (max-width: 576px) {
    display: none;
  }
}

/*----------------------------*/
.progress-head img {
  max-width: 60px;
}

.block-text {
  font-weight: 700;
}

.spider-chart {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.pdfHead {
  &-title {
    margin-top: 250px;
    margin-bottom: 150px;
  }

  &-data-wrapper {
    padding-bottom: 150px;
  }

  &-data-definition {
    color: #27a4a1;
    @include fontSize(18px);
    font-weight: bold;
    padding-right: 40px;
  }

  &-data {
    font-іize: 18px;
    font-weight: bold;
  }

  &-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.print-footer {
  bottom: 0;
  @include fontSize(12px);
  color: rgba(46, 46, 46, 0.39);
}

//AdvancedHeadIntro styles

.advanced-candidate-sec-report-data {

  .top-card {
    .CircularProgressbar .CircularProgressbar-text {
      @include fontSize(16px);
    }
  }

  .circular-bar-advance-card {
    border: none;
    width: 30%;
    padding: 20px 2% 5px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    code {
      background: rgba(37, 164, 161, 0.5);
      display: inline-block;
      padding: 0 5px;
      font-weight: bold;
      color: inherit;
      border-radius: 3px;
      margin-right: 5px;
      text-transform: uppercase;
    }

    span {
      margin: 0 0 15px 10px;
      @include fontSize(14px);
      font-weight: 700;
      text-align: left;
      display: block;
      width: 60% !important;
      margin-left: 4%;
    }

    .CircularProgressbar {
      max-width: none;
      min-width: 0;
      width: 30%;
      margin-bottom: 15px;
    }
  }
}

.candidate-report {
  .personalityTest-circular-progress-block {
    display: block;
    margin-bottom: -50px;
  }
}

//FINANCIAL ANALYST ROLE

.financial-analyst {

  .card {
    border: solid 1px #c0c1c3;
    border-radius: 10px;
  }

  .head-card{
    font-weight: 700;
    margin-top: 10px;
  }

  .ptext {
    font-size:1rem;
  }

  .cardPotential{
    border: solid 1px #c0c1c3;
    border-radius: 10px;

  }

  .listw {
    & > ul {
      @include fontSize(14px);
      padding-left: 10px;
    }

    &.rtl > ul {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  &-title {
    margin-bottom: 40px;
    font-weight: 700;
  }

  &-subtitle {
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 10px;
  }

  &-img-wrapper {
    max-width: 30px;
    padding-top: 25px;

    img {
      width: 30px;
      height: 30px;
      background-size: cover;
    }
  }

  .card-details-title {
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}


// teamFitDiversity

.teamFitDiversity-dot {
  height: 25px;
  width: 25px;
  background-color: #f30;
  border-radius: 50%;
  margin-bottom: -5px;
  display: inline-block;

  &.teamFitDiversity-dot-green {
    background-color: rgb(37, 164, 161);
  }

  &.teamFitDiversity-dot-red {
    background-color: #FF0000;
  }
}

.teamFitDiversity {
  margin-bottom: 80px;

  .chart-wrapper {
    position: relative;
    height: 800px;
  }

  .main-emp {
    width: 80px;
    height: 80px;
    display: block;
    border: 6px solid;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    background: white;
  }

  .emp-block{
    width: 80px;
    height: 80px;
    display: block;
    border: 6px solid;
    border-radius: 50%;
    position: absolute;
    left: calc(100% + 34px);
    top: 0;
    bottom: 0;
    margin: auto;
    transform-origin: center;
    z-index: 20;
  }

  .emp-block span {
    position: absolute;
    left: 50%;
    margin: auto;
    top: 72px;
    display: block;
    white-space: nowrap;
    text-align: center;
    transform: translateX(-50%);
  }

  .emp {
    width: 280px;
    height: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 50%;
    transform-origin: left;
  }

  .emp:after {
    content: " ";
    display: block;
    border-left: 12px solid;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    position: absolute;
    left: 100%;
    top: -4px;
  }

  .main-emp-firstName {
    position: absolute;
    top: 46px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    @include fontSize(12px);
    font-weight: bold;
    width: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
  }

  .emp-block-name {
    left: 0;
    position: absolute;
    text-align: center;
    @include fontSize(12px);
    font-weight: bold;
    white-space: nowrap;
    margin-left: 36px;
    margin-top: 3px;
  }

  .chart-descr-wrapper {
    text-align: center;
    margin-top: 45px;
  }

  .team-fit-title {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.fitcard > CircularProgressbar {
  width: 25%;
}


//personality test candidate report intro summary

.candidate-report {

  &-with-token {
    .test-result-block-button.theme-bg,
    .footer.dashboard-footer {
      display: none;
    }
  }

  .theme-bg {
    background: #27A4A1 !important;
  }

  &-content-title-wrapper {
    display: flex;
    justify-content: space-between;

    .content-title {
      font-weight: 600;
    }
  }

  &-actions-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0 40px;
  }
}






//CIRCULAR BLOCK IN REPORT INTRO

.intro-candidate-circular-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  .circular-block-card {
    width: 32%;
    padding: 20px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #c0c1c3;
    border-radius: 10px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (max-width: 576px) {
      padding: 20px 10px;
      border: none;
      box-shadow: 0px 3px 15px 0px #93939329;
    }

  }

  .CircularProgressbar {
    width: 95px;
    height: 95px;
    margin-bottom: 0;

    @media only screen and (max-width: 992px) {
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
  }

  .circular-block-card span {
    display: inline-block;
    width: calc(100% - 120px);
    margin: 0 0 0 15px;
    @include fontSize(24px);
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 1199px) {
      @include fontSize(20px);
    }

    @media only screen and (max-width: 576px) {
      width: unset;
      margin-left: 20px;
    }

    @media only print {
      @include fontSize(20px);
    }
  }

  .personalityTest-report-CircularProgress {
    @media only screen and (max-width: 992px) {
      width: 60px;
      height: 60px;
    }
  }
}


//STRENGTH BLOCK

@media print {

  .strength-sec-card {
    display: flex !important;
  }
}

.strength-sec {
  //margin-bottom: 50px;
  //padding-top: 50px;

  @media only screen and (max-width: 767px) {
    padding-top: 0;
  }

  &-container {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 2px solid #000;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      border-bottom: none;
    }
  }

  &-column {
    width: 50%;
    min-width: 50%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &-column-nowidth {
    width: 33%;
    .identity-block-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h6 {
    font-weight: 600;
    }
    ul {
      font-size: 10px;
      margin: 0px;
      li:before {
        top: 6px;
      }
    }
    .chosen-box {
      li{
        color: rgb(37, 164, 161);
        font-weight: bold
      }
    }
  }

  &-title-wrapper {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #000;
    padding: 20px 0;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &-card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px;
    border-bottom: 1px solid #ccc;
    height: 180px;
    width: 95%;

    &.dynamic-page{
      padding: 5px 5px 5px 15px;
      height:99px;
      width: 100%;
      &.ar {
        padding: 5px 15px 5px 5px;
      }
    }

    &:last-of-type {
      border-bottom: none;

      @media only screen and (max-width: 767px) {
        border-bottom: 2px solid #000;
      }
    }

    @media only screen and (max-width: 767px) {
      height: unset;
      padding: 30px 10px;
      width: 100%;
    }

    h4 {
      margin-bottom: 8px;
      text-transform: capitalize;
      font-weight: 700;
    }

    .performing-best-list-wrapper-custom {
      align-items: center;
      justify-content: center;
      display: grid;
      @media only screen and (max-width: 767px) {
        margin-left: 20px;
      }
    }

    .performing-best-list-wrapper {
      @media only screen and (max-width: 767px) {
        margin-left: 20px;
      }

      ul {
        padding-left: 0;
        padding-right: 0;

        li {
          overflow: visible !important;
        }
      }
    }

    &.dynamic-page{
      display: flex;
      align-items: center;
    }

    &.identity-list-wrapper-custom {
      justify-content: center;
      align-items: center;
    }

    &.identity-list-wrapper {
      justify-content: flex-start;
      &.dynamic-page{
      justify-content: center;
      }
      .identity-svg {
        width: 15%;
        margin-right: 20px;

        &.rtl {
          margin-right: 0;
          margin-left: 20px;
        }

        img {
          max-width: 100%;
        }
      }

      .identity-block-content {
        width: calc(100% - 15% - 20px);
      }
    }
  }
}

//ASSESSMENT SUMMARY PROGRESS

.progress-report {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }

  p {
    @include fontSize(12px);
  }

  p span {
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 10px;
  }
}

.progress-wrap {
  margin-bottom: 10px;

  .progress-bar {
    background-color: rgb(37, 164, 161);
    position: relative;
    border-radius: 10px;
  }

  .progress {
    border-radius: 10px;
    overflow: visible;
  }

  ul {
    padding-left: 15px;

    li {
      overflow: visible !important;
    }
  }
}

.progress-report-custom .progress-wrap {
  float: left;
}
.progress-report-custom .progress-wrap-rtl {
  float: right;
}

.progress-count {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid rgb(37, 164, 161);
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -2px;
  color: rgb(37, 164, 161);
  @include fontSize(8px);
  font-weight: 700;
  line-height: 15px;

  &.rtl {
    right: unset;
    left: -2px;
  }
}

//CAREER DRIVERS

.thumb-block [class*='col-'] {
  display: flex;
}

.thumb-block {
  margin-top: 50px;
}

.report-driverImg {
  width: 50px;
  background-size: cover;
}

.report-driverCard {
  width: 100%;
  padding: 20px;
  display: block;
  margin-bottom: 30px;
  border: 1px solid #c0c1c3;
  border-radius: 10px;

  &-title {
    @include fontSize(24px);
    font-weight: 600;
    margin: 0 20px;
  }

  img {
    max-width: 50px;
    max-height: 50px;
  }
}







.rtl {
  ul li:before {
    left: unset;
    right: -10px;
  }

  .progress-title img {
    margin-left: 10px;
    margin-right: 0;
  }

  .progress-title strong {
    margin-left: 10px;
    margin-right: 0;
  }

  .progress-report p span {
    margin-left: 10px;
    margin-right: 0;
  }

  .report-driverCard {
    &-title {
      margin: 0 20px 0 0;
    }
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
    margin-top: 10px;
  }
}



//check
//.personalityTest-card

