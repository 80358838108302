:root {
  --progress-wrap-height: 100px;
}

.print-cover {
  display: none;
}

@media print {
  /* @see https://stackoverflow.com/questions/2392366/print-background-colours-in-chrome */
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact; /*firefox & IE */
  }
  /* certain elements ought to be hidden on print */
  .print-button,
  header div {
    display: none !important;
  }
  /* certain elements ought to be visible on print */
  .print-cover {
    padding-top: 50%;
    display: flex;
  }

  /* certain element should not be separated by page-breaks */
  .assessment-sec,
  .progress-report {
    break-inside: avoid;
  }

  /* header and footer need to fixed in order to be displayed on every page */
  header {
    /*position: fixed !important;*/
    /*top: 0;*/
    border-bottom: 1px solid #eee;
  }
  /* custom footer is used for printing */
  #pdf-root footer {
    display: none;
  }
  #pdf-root .print-footer {
    display: flex !important;
  }

  /* Cuustom print for iPhone and Safari print action */
  /* @see https://browserstrangeness.bitbucket.io/css_hacks.html#safari */
  @media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) and (stroke-color:transparent) { 
      #pdf-root .print-footer {
        display: block !important;
        position: fixed !important;
        bottom: -215mm;
        width: 200%;
      }
      .header-border {
        display: block;
        height: 70px;
        border-bottom: 1px solid #eee;
        width: 100%;
      }

      header, header:before{
        border-bottom: none !important;
      }
      header .header-logo-link {
        max-width: unset !important;
        height: unset !important;
      } 
      header .header-logo-link .header-logo {
        width: 250px !important;
      }
      header:after, #pdf-root .print-footer:after {
        display: block !important;
      }
      .career-driver-block {
        display: block;
        page-break-before: always;
        margin-top: 10px;
      }
      .assessment-sec, .candidate-report-paragraph {
        margin-bottom: 5px !important;
      }
      .progress-report, .progress-report-heading {
        margin-bottom: 0 !important;
      }
      .progress-report-section {
        height: 1300px !important; /* A4 page height without margin and heading */ 
      }
      .progress-report-execute {
        margin-top: 10px !important;
      }
    } 
  }

  /* @see https://stackoverflow.com/questions/20050939/print-page-numbers-on-pages-when-printing-html */
  /* @page {
    @bottom-right {
      content: counter(page) '/' counter(pages);
    }
  } */
}
.d-flex {
  display: flex!important;
}
.flex-md-row {
  flex-direction: row!important;
}
.flex-md-column {
  flex-direction: column!important;
}
.col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.mt-md-1, .my-md-1 {
  margin-top: 0.25rem!important;
}
.ml-md-0, .mx-md-0 {
  margin-left: 0!important;
}
.mt-md-0, .my-md-0 {
  margin-top: 0!important;
}
.mt-4, .my-4 {
  margin-top: 1.5rem!important;
}
.pt-5, .py-5 {
    padding-top: 3rem!important;
}
.mt-5, .my-5 {
    margin-top: 3rem!important;
}
.mw-100 {
  max-width: 100%!important;
}
.position-fixed {
  position: fixed!important;
}
.position-static {
  position: static!important;
}
.justify-content-between {
  justify-content: space-between!important;
}
.assessment-sec {
  padding-top: 50px;
  margin-bottom: 0px;
}
.progress-heading {
  margin-bottom: 0px !important;
}
.progress-report {
  margin-bottom: 0px;
}
.progress-report-execute {
  margin-top: calc(var(--progress-wrap-height) / 2);
}
.progress-report p {
  margin-bottom: 0px;
  font-size: 12px;
}
.progress-report > p:first-of-type {
  height: calc(var(--progress-wrap-height) / 2);
  display: flex;
  align-items: center;
}
.progress-wrap {
  height: var(--progress-wrap-height);
  margin-bottom: 0px;
  padding-bottom: calc(var(--progress-wrap-height) * 0.1);
}
.progress-wrap p {
  min-height: calc(var(--progress-wrap-height) * 0.4);
}

.progress-report-custom .progress-wrap {
  height: var(--progress-wrap-custom-height);
  margin-bottom: 0px;
  padding-bottom: calc(var(--progress-wrap-custom-height) * 0.1);
}

@media print {
  .bg-teal-light th, .bg-teal-light td {
    background-color:rgba(37, 164, 161, 0.1) !important;
  }
}