@import '../../styles/mixins';

.gameDescription {
  color: #353535;
  text-align: center;
  @include fontSize(12px);
  user-select: none;
  font-weight: 500;
  margin: 0 auto;
}

.gameTaskWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px auto 0;
  width: 380px;

  @media screen and (max-width: 576px) {
    width: 90%;
  }
}

.gameBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  width: 350px;
  max-width: 100%;
  height: 140px;
  border-radius: 8px;
  object-fit: cover;
}

.buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0 0;
}

.buttonNext {
  outline: none !important;
  border: none;
  min-width: 110px;
  height: 36px;
  background: #27A4A1;
  margin: auto;
  margin-top: 25px;
  border-radius: 30px;
  box-shadow: 0 0 16px #e2eeed;
  color: #ffffff;
  font-weight: 900;
  text-align: center;
  transition: .4s;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @include fontSize(12px);
  cursor: pointer;
}

.buttonNext:hover {
  color: #ccc;
}

.confirmButton {
  outline: none !important;
  border: none;
  background: #27A4A1;
  border-radius: 30px;
  box-shadow: 0px 0px 16px #e2eeed;
  color: #ffffff;
  font-weight: 500;
  @include fontSize(12px);
  width: 80px;
  text-align: center;
  transition: 0.4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 35px;
  margin: 0 15px;
  cursor: pointer;
}

// this checks if the device actually has hover capabilities (as opposed to simulated hover to prevent sticky hover styles)
@media (hover: hover) {
  .confirmButton:hover {
    color: #ccc;
  }
}

.modalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 15;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 45px;
  box-shadow: 0 0 12px #c8c8c8;
  border-radius: 4px;
  background: #fff;
  width: 400px;
}


.endPractice {
  outline: none !important;
  border: none;
  min-width: 160px;
  height: 36px;
  background: #27A4A1;
  margin: auto;
  margin-top: 25px;
  border-radius: 30px;
  box-shadow: 0 0 16px #e2eeed;
  color: #ffffff;
  font-weight: 900;
  text-align: center;
  transition: .4s;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @include fontSize(12px);
  cursor: pointer;
}

.disabledBtn {
  background: #dfdfdf;
}

.disabledBtn:hover {
  color: #ffffff;
}
