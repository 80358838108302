@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@import 'mixins';
@import 'common';
@import 'header';
@import 'footer';
@import 'vacancy';
@import 'aside';
@import 'test-progress-navigation';
@import 'test-forms/questionnaire-assessment';
@import 'upload-cv';
@import 'content';
@import 'test-forms/personality-test';
@import 'test-forms/sjt-part-one';
@import 'test-forms/sjt-part-two';
@import 'test-details-mobile';
@import 'auth-form';
@import 'buttons';
@import 'checkbox';
@import 'steps';
@import 'links';
@import 'profile-settings';
@import 'tip-popup';
@import 'help-pop-up';
@import 'loader';
@import 'customRadioBtn';
@import 'modalDelete';
@import 'testProgressBar';
@import 'personalityTest';
@import 'sjtTest';
@import 'careerDrivers';
@import 'reports';
@import 'games';
@import 'video';

$sizes: map-merge(
  (
          0: 0,
  ),
  $sizes
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .h-#{$breakpoint}-#{$size} {
        height: $length !important;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.container {
  @media (min-width: 576px ) and (max-width: 767px) {
    max-width: unset;
  }
}

.whitebox__card-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .whitebox__card-wrapper {
    --box-size: 200px;
    width: var(--box-size);
    height: var(--box-size);
    margin-right: 20px;
    margin-bottom: 20px;

    &.rtl {
      margin-right: 0;
      margin-left: 20px;
    }

    .whitebox__card {
      height: 100%;

      &-icon {
        width: 45px;
        height: 45px;

        svg {
          width: 45px;
          height: 45px;
          max-width: 45px;
          max-height: 45px;
        }
      }

      &-title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .whitebox__card-wrapper {
    min-width: 100%;
  }
}

/* Login form */
.login-form-b-list {
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
}

#root {
  overflow-x: hidden;
}

.login-form-b-list li {
  display: inline-block;
  margin: 0 6px;
}

/* Error */
.small-error {
  color: #ff4c4c !important;
  @include fontSize(13px);
  line-height: 13px;
  display: block;
  margin-bottom: 10px;
}

.test-question-title {
  color: #302f2f;
  @include fontSize(14px);
  line-height: 25px;
  margin: 0 0 15px;
}

.test-choose-answer {
  color: rgba(51, 51, 51, 0.8);
  @include fontSize(10px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.96px;
}

.content-wrapper {
  display: flex;
  width: 100%;
  // align-content: space-between;
  // flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 45px;
  min-height: 100vh;
  position: relative;

  @media only screen and (max-width: 767px) {
    padding-bottom: 70px;
  }
}

/* Test timer and btn */
.test-timer-and-btn {
  display: flex;
  align-items: center;
}

.clock-icon {
  height: 36px;
  width: 36px;
  background-color: #ffeeee;
  border-radius: 35px;
  display: inline-block;
  margin-right: 7px;
  position: relative;
}

.clock-icon:after {
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;
  height: 22px;
  width: 22px;
  background-image: url(/assets/img/timer.svg);
}

.test-timer-and-btn p {
  display: inline-block;
  margin: 0;
  margin-right: 16px;
  color: #2e2e2e;
  @include fontSize(14px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.12px;
}

.test-timer-and-btn button {
  padding: 0 24px;
  min-width: 100px;
  height: 45px;
}
ul {
  list-style: none;

  @media only screen and (max-width: 767px) {
    padding-inline-start: 15px;
  }

  li {
    overflow: hidden;
  }
}
.MuiSlider-root {
  color: #27a4a1 !important;
}
// #cards.cards.uniqueCalssForCareerDrivers {
//   height: auto !important;
// }

footer.footer {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1140px;
  background: #ffffff;
  z-index: 99;

  @media screen and (max-width: 767px) {
    padding: 10px 0 0 0;
  }
}

body,
html {
  height: 100%;
}
.show-pdf {
  display: none;
}

img.user-img {
  height: 69px;
  border-radius: 50%;
}

.mb-35 {
  margin-bottom: 35px;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.assessment-submit-btn {
  padding: 10px;

  &-example {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.text-align-start {
  text-align: start !important;
}
