.footer {
  min-height: 45px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer:before {
  content: "";
  position: absolute;
  width: 100vw;
  z-index: -1;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-top: 1px solid #eaeaea;
}

.footer-wrapper {
  border-top: 1px solid #eaeaea;
}

.footer-copyright {
  color: rgba(46, 46, 46, 0.39);
  letter-spacing: 0.48px;
  margin: 0;
  @include fontSize(12px);
}

.footer-links {
  list-style: none;
  margin: 0 32px 0 0;
  line-height: 1;
}

.footer-links li {
  display: inline-block;
}

.footer-links:not(.rtl) li:first-child,
.footer-links.rtl li:last-child {
  margin-right: 40px;
}

.footer-links li a {
  letter-spacing: 0.48px;
  @include fontSize(12px);
}

@media screen and (min-width: 992px) {
  .dashboard-footer {
    border: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .footer {
    padding: 0;
  }

  .footer-links{
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  footer.footer {
    min-height: 60px;
    border-top: none;
    flex-direction: column;
    align-items: baseline;
    padding: 10px 0 0 0;
  }

  .footer-copyright {
    order: 1;
    margin-bottom: 10px;
  }

  .footer-links {
    margin-right: 0;
    padding: 0;
    margin-bottom: 10px;
  }
}
