.sjt-form-part-two {
  display: flex;
  flex-wrap: wrap;
}

.sjt-form-part-two label {
  height: 50px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  color: #9a9a9a;
  @include fontSize(15px);
  line-height: 50px;
  padding: 0 20px;
  margin-right: 10px;
}

.sjt-form-part-two div input {
  display: none;
}

.sjt-sjt-form-part-two-input label[for="1"].active,
.sjt-sjt-form-part-two-input label[for="1"]:hover,
.sjt-form-part-two-input input:checked ~ label[for="1"] {
  background-color: rgba(252, 74, 95, 0.83);
  color: #333333;
}

.sjt-form-part-two-input label[for="2"]:active,
.sjt-form-part-two-input label[for="2"]:hover,
.sjt-form-part-two-input input:checked ~ label[for="2"] {
  background-color: rgba(254, 129, 143, 0.72);
  color: #333333;
}

.sjt-form-part-two-input label[for="3"].active,
.sjt-form-part-two-input label[for="3"]:hover,
.sjt-form-part-two-input input:checked ~ label[for="3"] {
  background-color: #ffd7dc;
  color: #333333;
}

.sjt-form-part-two-input label[for="4"].active,
.sjt-form-part-two-input label[for="4"]:hover,
.sjt-form-part-two-input input:checked ~ label[for="4"] {
  background-color: #fde5ca;
  color: #333333;
}

.sjt-form-part-two-input label[for="5"].active,
.sjt-form-part-two-input label[for="5"]:hover,
.sjt-form-part-two-input input:checked ~ label[for="5"] {
  background-color: #dcfdd0;
  color: #333333;
}

.sjt-form-part-two-input label[for="6"].active,
.sjt-form-part-two-input label[for="6"]:hover,
.sjt-form-part-two-input input:checked ~ label[for="6"] {
  background-color: #c2ffac;
  color: #333333;
}

.sjt-form-part-two-input label[for="7"].active,
.sjt-form-part-two-input label[for="7"]:hover,
.sjt-form-part-two-input input:checked ~ label[for="7"] {
  background-color: #a7f08d;
  color: #333333;
}
