.header {
  // height: 70px;
  width: 100%;
  min-height: 28px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  .lang-btn {
    width: 50px;
    color: #c8c8c8;

    &-active {
      font-weight: 800;
      color: #FFFFFF;
      @include fontSize(14px);
    }
  }
}

.header:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eaeaea;
  width: 100vw;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.header-wrapper {
  border-bottom: 1px solid #eaeaea;
}

.header-logo-link {
  display: inline-block;
  max-width: calc(100vw - 297px);
  height: 65px;
  line-height: 15px;
  vertical-align: middle;
  @media screen and (min-width: 500px) {
    max-width: 195px;
  }
  @media print {
    height: 25px;
  }
}

.header-logo {
  // margin-top: 11px;
  //width: 158px;
  height: 100%;
  max-width: 100%; 
  object-fit: contain;
  padding: 5px 0;
  @include fontSize(10px);
  @media screen and (max-width: 298px) {
    display: none;
  }
}

.header-text {
  display: inline-block;
  color: #ff9645;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  margin: 0 33px 0 0;
  @include fontSize(10px);
}

.none {
  display: none;
}

.dropDownMenus {
  position: relative;
  user-select: none;
}

.block {
  display: flex;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0 0 18px #c8c8c8;
  right: -4px;
  border-radius: 4px;
  top: 26px;
  width: 123px;
  z-index: 30;

  @include fontSize(10px);

  &.rtl {
    left: -4px;
    right: unset;
  }
}

.headerLinks, .headerLinks:hover {
  text-decoration: none;
  color: #c6c6c6;
  font-weight: 700;
}

.listItem {
  padding: 7px 0;
  width: 100%;
  transition: .4s;
  cursor: pointer;
  @include fontSize(10px);
}

.listItem:hover {
  background: #27A4A1;
}

.listItem:first-child:hover {
  border-radius: 4px 4px 0 0;
}

.listItem:last-child:hover {
  border-radius: 0 0 4px 4px;
}

.listItem:hover .headerLinks {
  color: #FFFFFF;
}

.dropDownIcon {
  padding: 0 10px 0 0;
  @include fontSize(13px);
}

body[dir='rtl'] {
  .dropDownIcon {
    padding: 0 0 0 10px;
  }
}

.dropDownItem {
  padding: 0 5px;
}

.spanItem {
  display: flex;
  align-items: center;
} 

@media screen and (min-width: 992px) {
  .dashboard-header {
    border: none;
    padding-right: 0;
    padding-left: 0;
  }

  //.header:before {
  //  content: "";
  //  position: absolute;
  //  border-bottom: 1px solid #eaeaea;
  //  width: calc(100% + 200px);
  //  bottom: 0;
  //  left: -100px;
  //}
}

@media screen and (max-width: 991px) {
  .header {
    border-bottom: none !important;
    padding: 0;

    .lang-btn {
      width: 40px;
      padding: 2px 10px;
      line-height: 17px;

      &-active {
        @include fontSize(12px);
      }
    }
  }

  .header-text {
    margin-right: 0;
  }
}

.profile-edit-button.mobile, .mobile:hover{
  margin-bottom: 0;
  margin-left: 14px;
  height: 45px;
  width: 100px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #b5b5b5;
}

.profile-edit-button.mobile:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  background: url(/assets/img/aside-settings-icon.png);
  height: 17px;
  width: 17px;
  transform: translate(0,-50%);
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

