.drag-and-drop {
  border-radius: 4px;
  border: 1px dashed #27a4a1;
  background-color: #f9f9f9;
  padding: 24px 40px 34px 40px;
  margin-bottom: 39px;
  text-align: center;
}

.dad-title {
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
}

.dad-text-or {
  color: #2e2e2e;
  line-height: 30px;
}

.dad-text {
  font-style: italic;
  letter-spacing: 0.24px;
  line-height: 18px;
}

.upload-cv-uploaded-files {
  margin-bottom: 4rem;
  list-style: none;
  padding-left: 0;
}

.upload-cv-uploaded-files li {
  line-height: 32px;
  color: #333333;
  @include fontSize(14px);
  position: relative;
  margin-bottom: 10px;
}

/*.upload-cv-uploaded-files li:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 0;
  border-top: 3px dotted #cdcdcd;
  height: 3px;
  width: 200px;
}*/

.upload-cv-uploaded-files li {
  line-height: 32px;
  color: #333333;
  @include fontSize(14px);
  position: relative;
}

.upload-cv-uploaded-files li img {
  height: 32px;
  margin-right: 8px;
  display: inline-block;
}

.uploadBtn {
  min-width: 160px !important;
  padding: 0 !important;
  height: 36px !important;
  @include fontSize(10px, '!important');
  margin-top: -3px !important;
}
