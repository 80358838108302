.test-details-mobile {
  min-height: 46px;
  background-color: #f7f7f7;
  position: relative;
  width: 100%;
  z-index: 1;
}

.test-details-mobile:before {
  content: "";
  position: absolute;
  background-color: #f7f7f7;
  height: 100%;
  width: 100vw;
  z-index: -1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-bottom: 1px solid #eaeaea;
}

.test-details-mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-details-mobile-title {
  opacity: 0.8;
  color: #303030;
  @include fontSize(11px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.88px;
  margin: 0;
  line-height: 45px;
}

.test-details-mobile-btn {
  height: 19px;
  width: 19px;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
}

.test-details-mobile-btn.open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.test-details-mobile-btn:focus {
  outline: none;
}

.test-details-mobile-btn img {
  height: 19px;
  width: 19px;
  display: block;
}


.test-details-mobile-content {
  margin-top: 15px;
  margin-bottom: 27px;
}
