.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include fontSize(22px);

  &.checkbox-container-disabled {
    cursor: not-allowed;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #27A4A1;
  }

  &.rtl {
    padding-left: unset;
    padding-right: 35px;

    input {
      left: unset;
      right: 0;
    }

    .checkmark {
      left: unset;
      right: 0;
    }
  }
}

.checkmark.radio {
  border-radius: 27px;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #c8c8c8;
}

.checkbox-container.checkbox-container-disabled input ~ .checkmark {
  background-color: unset;
  border-color: #cccccc !important;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #27A4A1;
  border: 1px solid #27A4A1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
