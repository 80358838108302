.tr8s-steps {
  list-style: none;
  padding: 0;
}

.tr8s-steps li {
  color: #303030;
  @include fontSize(10px);
  display: flex;
  margin-bottom: 13px;
  align-items: center;
}

.rtl.tr8s-steps li span {
  margin-right: 0;
  margin-left: 9px;
}

.tr8s-steps li span {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  background-color: #d1d1d1;
  display: inline-block;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  margin-right: 9px;

  position: relative;
  @include fontSize(11px);
  font-weight: 700;
  line-height: 25px;

  &.rtl {
    margin-right: 0;
    margin-left: 9px;
  }
}

.tr8s-steps li p {
  margin-bottom: 0;
}

.tr8s-steps li p:not(.text):last-child {
  @include fontSize(10px, '!important');
  color:#e5e5e5;
  font-weight: 500;
}

.tr8s-steps li span.active {
  background-color: #27A4A1;
  color: #27A4A1;
}

.tr8s-steps li span.active:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 5px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.tr8s-steps li span:before {
  content: "";
  height: 27px;
  width: 1px;
  position: absolute;
  background: #d1d1d1;
  top: 25px;
  left: 12px;
}

.tr8s-steps-last:before {
  display: none;
}
