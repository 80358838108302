//@import 'node_modules/bootstrap/scss/variables';

.video-rtl {
  .MuiSelect-icon {
    right: unset;
    left: 0;
  }

  .MuiInputLabel-formControl {
    left: unset;
    right: 0;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-left: 24px;
    padding-right: 0;
  }

  .MuiInputLabel-shrink {
    transform-origin: top right;
  }
}

.video-layout-left {
  width: 100%;
}

.video-layout-right {
  width: 100%;
}

.video-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
}

.standby-recording-overlay-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

@media all and (min-width: map-get($grid-breakpoints, 'xl')) {
  .video-layout-left {
    width: 40%;
  }

  .video-layout-right {
    width: 60%;
  }
}
