.gameDescription {
    color: #353535;
    position: relative;
    padding: 0 50px;
    text-align: center;
    font-size: 15px;
    user-select: none;
    font-weight: 700;
    animation: moveTop 1s ease 0s;
    font-size: 12px;
}

.textWrapper {
    overflow: hidden;
}

.gameFieldWrapper {
    width: 380px;
    max-width: 90%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 25px auto auto;
}

.gameFiled {
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 270px;
    margin-top: 30px;
}

.arrowIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #E8E8E8;
}

.leftArrow {
    transform: scaleX(-1);
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 165px;
    color: #E8E8E8;
}

.rightArrow {
    transform: scaleX(1);
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 165px;
    color: #E8E8E8;
}

.itemWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px 18px 0 0;
    flex-direction: column-reverse;
    position: relative;
}

.itemsGrid {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    width: 150px;
}

.itemWrapper:last-child {
    border-radius: 18px;
}
.testItems {
    background: #909cec;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.testItems:nth-last-child(4) {
    width: 60px;
    height: 60px;
    z-index: 0;
    top: -30px;
}

.testItems:nth-last-child(3) {
    width: 90px;
    height: 90px;
    z-index: 1;
    top: 5px;
}

.testItems:nth-last-child(2) {
    width: 120px;
    height: 120px;
    z-index: 2;
    top: 45px;
}
.testItems:nth-last-child(1) {
    width: 160px;
    height: 160px;
    z-index: 3;
    top: 100px;
}


.testItems:nth-last-child(1) img  {
    opacity: 1;
    width: 110px;
    height: 110px;
}

.testItems:nth-last-child(2) img {
    opacity: .8;
    width: 80px;
    height: 80px;
}

.testItems:nth-last-child(3) img {
    opacity: .6;
    width: 60px;
    height: 60px;
}

.testItems:nth-last-child(4) img {
    opacity: .4;
    width: 40px;
    height: 40px;
}

.bottomIcons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.iconsWrapper {
    background: #ffffff;
    box-shadow: 1px 1px 30px #c8c8c8;
    border-radius: 12px;
    cursor: pointer;
}

.variantWrapper {
    height: 120px;
}

.lateText {
    text-align: center;
    font-weight: 600;
    color: #dedede;
    padding-top: 5px;
}

.bottomIcon {
    width: 65px;
    height: 65px;
    margin: 10px;
}

.arrowR {
    position: relative;
    animation: moveR 1s ease 0s;
}

.arrowL {
    position: relative;
    animation: moveL 1s ease 0s;
}

.truth {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #98c8c2;
    border-radius: 50%;
    color: #ffffff;
    font-size: 25px;
    margin: 0 auto;
}

.incorrect {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #e17e7e;
    border-radius: 50%;
    color: #ffffff;
    font-size: 25px;
    margin: 0 auto;
}

.keyboardHelper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #cdcdce;
}

.keyboardHelper p {
    color: #cdcdce;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
}

.keyboardHelperArrow {
    display: flex;
}

.keyboardHelperArrow div {
    font-size: 20px;
    border-radius: 4px;
    border: 2px solid #cdcdce;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.buttonNext {
    font-size: 12px;
    min-width: 160px;
    height: 36px;
    background: #27A4A1;
    margin-bottom: 25px;
    border-radius: 30px;
    box-shadow: 0 0 16px #e2eeed;
    color: #ffffff;
    font-weight: 900;
    font-size: 12px;
    text-align: center;
    transition: .4s;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
  }
  
  .buttonNext:hover {
    color: #ccc;
  }

@keyframes moveTop {
    0% {
        top: -120px
    }
    100% {
        top: 0;
    }
}
@keyframes moveR {
    0% {
        right: -120px;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}

@keyframes moveL {
    0% {
        left: -120px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}
