.aside-wrapper {
  padding: 14px 0 14px 20px;
  min-height: 100vh;
  height: 100%;
}

.aside-block-title {
  color: rgba(48, 48, 48, 0.8);
  @include fontSize(11px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.88px;
  margin-bottom: 16px;
}
