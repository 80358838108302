.example-popup-form {
  position: relative;
}

.help-popup {
  max-width: 314px;
  min-width: 165px;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.17);
  border-radius: 0 20px 20px;
  position: absolute;
  background: #ffffff;
  z-index: 115;
  left: 385px;
  top: 6px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.help-popup-header {
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.88px;
  border-radius: 0 20px 0 0;
  background-color: #27a4a1;
  height: 34px;
  line-height: 34px;
  margin-bottom: 17px;
  position: relative;
  @include fontSize(11px);
}

.triangle-topright {
  width: 0;
  height: 0;
  border-top: 34px solid #27a4a1;
  border-left: 54px solid transparent;
  position: absolute;
  left: -25px;
}

.help-popup-text {
  margin-bottom: 15px;
  padding: 0 15px;
  font-weight: normal;
  text-transform: none;
  @include fontSize(14px);

  opacity: 0.74;
  color: #333333;
  line-height: 22px;
  @include fontSize(13px);
}

.help-popup-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 33px;
}

.help-popup-btn {
  color: #b5b5b5;
  font-weight: 600;
  letter-spacing: 0.26px;
  margin-right: 5px;
  margin-left: 5px;
  @include fontSize(13px);
}

.help-popup-btn.green {
  width: 104px;
  height: 36px;
  border-radius: 18px;
  background-color: #27A4A1;
  color: #ffffff;
}

.help-popup-btn.gray {
  width: 104px;
  height: 36px;
  border-radius: 18px;
  background-color: #f3f3f3;
}
