.questionnaire-assessment {
  & > ul {
    padding: 0;
    list-style: none;
  }

  & > ul > li {
    display: flex;
  }

  & > ul > li > p {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    background-color: rgba(255, 150, 69, 0.17);
    margin-right: 17px;

    color: #ff9645;
    @include fontSize(16px);
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }

  & > ul > li > div > p {
    color: #302f2f;
    @include fontSize(16px);
    line-height: 34px;
  }

  & > ul > li > div > div {
    position: relative;
    user-select: none;
  }

  & > ul > li > div > div > div > div > label {
    color: #2e2e2e;
    @include fontSize(15px);
    vertical-align: bottom;
    display: inline-block;
  }

  & > ul > li > div > div > input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__question-number {
    margin-right: 10px;
  }

  &__select-question {
    outline: none;
    min-width: 30%;
    width: 30%;
    border: none;
    border-bottom: 1px solid rgba(51, 51, 51, 0.15);
    border-radius: 0;
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 20px;
    color: #000;
    @include fontSize(15px);
    line-height: 30px;

    &:focus {
      border-bottom: 1px solid rgba(51, 51, 51, 0.15);
      box-shadow: none;
      color: inherit;
    }

    @media screen and (max-width: 767px) {
      min-width: 100%;
      width: 100%;
    }
  }

  &__text-question {
    width: 600px;
    max-width: 100%;
    height: 100px;
    border-radius: 4px;
    border: 1px solid rgba(51, 51, 51, 0.15);
    padding: 10px;
    outline: none;
  }
}

.koq-number-input {
  height: 34px;
  margin-bottom: 37px;
}

.koq-number-input button {
  height: 34px;
  width: 34px;
  background-color: #f3f3f3;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  border: none;
}

.koq-number-input input {
  height: 34px;
  margin: 0 9px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  max-width: 80px;
}
