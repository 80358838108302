.personality-test-form table th,
.personality-test-form table td {
  border: none;
  padding: 20px 0;
  vertical-align: middle;
}

.personality-test-form table tbody tr,
.personality-test-form .option-wrapper-mobile {
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 8px;
}

.personality-test-form table thead tr th {
  text-align: center;
  border: none;
  color: #2e2e2e;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.78px;
  box-sizing: border-box;
}

.personality-test-form table thead tr th p {
  width: 90px;
  padding: 10px;
  font-weight: 700;

  @media screen and (max-width: 992px) {
    width: 60px;
    padding: 5px;
  }
}

.table thead th {
  vertical-align: top;
}

.personality-test-form table th p {
  margin-bottom: 0;
  @include fontSize(12px);
  line-height: 13px;
  text-align: center;
}

.personality-test-form table tbody tr {
  border-bottom: 8px solid #fff;
}

.personality-test-form table tbody tr th {
  color: #2e2e2e;
  font-weight: 400;
  line-height: 24px;
  padding: 20px 28px;
  width: 100%;
}

.personality-test-form table tbody tr td {
  padding-right: 0;
}

.personality-test-form table tbody tr td label {
  height: 25px;
  width: 25px;
  margin: auto;
  padding: 0;
}

.test-test-next-btn {
  margin-left: calc(100% - 90px) !important;
  min-width: 90px !important;
  @include fontSize(12px, '!important');
  width: 90px !important;
  padding: 0px !important;
  height: 35px !important;
}

.flex {
  display: flex;
  align-items: center;
  height: 60px;
}

.option-wrapper-mobile {
  padding: 30px 15px;
  min-height: 195px;

  .checkmark {
    left: 50%;
    transform: translateX(-50%);
    border: none !important;
    height: 35px;
    width: 35px;
  }

  .checkbox-container input ~ .checkmark {
    background-color: #c8c8c8;
  }

  .checkbox-container span {
    display: flex;
    justify-content: center;
    align-items: center;
    @include fontSize(16px);
    color: #fff;

    &:after {
      content: none;
    }
  }

  .option-title-mobile {
    font-weight: 600;
    @include fontSize(16px);
    text-align: center;
    margin-bottom: 25px;
  }

  .option-scale-text-mobile {
    margin-top: 30px;
    margin-bottom: 0;
    text-align: center;
  }
}

.personality-test-form {
  .checkbox-container.disabled-checkbox-container:hover input ~ .checkmark,
  .checkbox-container input.disabled-checkmark ~ .checkmark:active {
    background-color: #f4494a;
  }
}
