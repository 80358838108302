
.itemr-enter {
  opacity: 0;
}

.itemr-enter-active {
  opacity: 0;
  transition: .5s;
}
.itemr-exit {
  right: 0px;
  opacity: 1;
}
.itemr-exit-active {
  opacity: 0;
  right: 300px;
  transition:  .5s linear;
}

.iteml-enter {
  opacity: 0;
}
.iteml-enter-active {
  opacity: 0;
  transition: .5s;
}
.iteml-exit {
  left: 0;
  opacity: 1;
}
.iteml-exit-active {
  left: 300px;
  opacity: 0;
  transition: .5s linear;
}

.itemb-enter {
  opacity: 0;
}
.itemb-enter-active {
  opacity: 0;
  transition: .5s;
}
.itemb-exit {
  margin-top: 0;
  opacity: 1;
}
.itemb-exit-active {
  margin-top: 300px;
  opacity: 0;
  transition: .5s linear;
}
  
.itemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px 18px 0 0;
  flex-direction: column-reverse;
  position: relative;
  height: 350px;
}


.itemWrapper:last-child {
  border-radius: 18px;
}