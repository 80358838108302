.test-progress-navigation {
  margin-bottom: 42px;
}

.test-progress-navigation ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.test-progress-navigation ul li {
  display: inline-block;
  margin: 0 5px;
}

.test-prog-nav-btn.prev,
.test-prog-nav-btn.next {
  padding: 0 32px;
  position: relative;
}

.test-prog-nav-btn.next {
  padding-left: 22px;
  padding-right: 35px;
}

.test-prog-nav-btn.next:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 13px;
  background-image: url(/assets/img/arrow-right.svg);
  height: 10px;
  width: 13px;
}

.test-prog-nav-btn {
  border-radius: 53px;
  outline: none;
  height: 31px;
  min-width: 31px;
  box-shadow: none;
  color: rgba(50, 50, 50, 0.35);
  background-color: #fff;
  border: 1px solid #d0d0d0;
  @include fontSize(13px);
}

.test-prog-nav-btn:focus {
  outline: none !important;
}

.test-prog-nav-btn.done {
  background-color: #27A4A1;
  color: #ffffff;
  border: none;
}

.test-prog-nav-btn.current {
  background-color: #ff954c;
  color: #ffffff;
  border: none;
}

.test-prog-nav-btn:hover {
  background-color: #d5d5d5;
  color: #ffffff;
}
